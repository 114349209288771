import { SalesSectionProps } from '.'

export const swapSectionData: SalesSectionProps = {
  headingText: 'Trade anything. No registration, no hassle.',
  bodyText: 'Trade any token on Binance Smart Chain in seconds, just by connecting your wallet.',
  reverse: false,
  primaryButton: {
    to: '/swap',
    text: 'Trade Now',
    external: false,
  },
  secondaryButton: {
    to: 'https://cybercowsnft.com/',
    text: 'Visit CyberCows Nft',
    external: true,
  },
  images: {
    path: '/images/home/trade/',
    attributes: [
      { src: ' ', alt: ' ' },
      { src: ' ', alt: ' ' },
      { src: '', alt: ' ' },
    ],
  },
}

export const earnSectionData: SalesSectionProps = {
  headingText: 'Earn passive income with crypto.',
  bodyText: 'MilkerSwap makes it easy to make your crypto work for you.',
  reverse: true,
  primaryButton: {
    to: '/farms',
    text: 'Explore',
    external: false,
  },
  secondaryButton: {
    to: 'https://cybercowsnft.com/',
    text: 'Visit Us',
    external: true,
  },
  images: {
    path: '/images/home/earn/',
    attributes: [
      { src: ' ', alt: ' ' },
      { src: ' ', alt: ' ' },
      { src: ' ', alt: ' ' },
    ],
  },
}

export const cakeSectionData: SalesSectionProps = {
  headingText: 'CyberCows makes our world go round.',
  bodyText:
    'Cybercows token is at the heart of the PancakeSwap ecosystem. Buy it, win it, farm it, spend it, stake it... heck, you can even vote with it!',
  reverse: false,
  primaryButton: {
    to: '/swap?outputCurrency=0x60781CACb7d4c01d6f87f00d8597880c9923dca1',
    text: 'Buy CyberCows',
    external: false,
  },
  secondaryButton: {
    to: 'https://docs.pancakeswap.finance/tokenomics/cake',
    text: '  ',
    external: false,
  },

  images: {
    path: '/images/home/cake/',
    attributes: [
      { src: ' ', alt: ' ' },
      { src: ' ', alt: ' ' },
      { src: ' ', alt: ' ' },
      { src: ' ', alt: ' ' },
    ],
  },
}
