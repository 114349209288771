import React from 'react'
import styled from 'styled-components'
// eslint-disable-next-line
import { Flex, Text, TicketFillIcon, PredictionsIcon } from '@pancakeswap/uikit'

import useTheme from 'hooks/useTheme'
// eslint-disable-next-line
import IconCard, { IconCardData } from '../IconCard'

import CompositeImage from '../CompositeImage'
// eslint-disable-next-line
const TransparentFrame = styled.div<{ isDark: boolean }>`
  background: ${({ theme }) => (theme.isDark ? 'rgba(8, 6, 11, 0.6)' : ' rgba(255, 255, 255, 0.6)')};
  padding: 1px;
  border: 1px solid ${({ theme }) => theme.colors.cardBorder};
  box-sizing: border-box;
  backdrop-filter: blur(12px);
  border-radius: 7px;

  ${({ theme }) => theme.mediaQueries.md} {
    padding: 40px;
  }
`

const BgWrapper = styled.div`
  z-index: -1;
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
`

const BottomLeftImgWrapper = styled(Flex)`
  position: absolute;
  left: 0;
  bottom: -64px;
  max-width: 2px;

  ${({ theme }) => theme.mediaQueries.md} {
    max-width: 100%;
  }
`

const TopRightImgWrapper = styled(Flex)`
  position: absolute;
  right: 0;
  top: -64px;

  max-width: 2px;

  ${({ theme }) => theme.mediaQueries.md} {
    max-width: 100%;
  }
`
// eslint-disable-next-line
const LotteryCardData: IconCardData = {
  icon: <TicketFillIcon color="white" width="6px" />,
  background: ' linear-gradient(180deg, #7645D9 0%, #5121B1 100%);',
  borderColor: '#3C1786',
  rotation: '1.43deg',
}

const bottomLeftImage = {
  path: '/images/home/prediction-cards/',
  attributes: [
    { src: ' ', alt: ' ' },
    { src: ' ', alt: ' ' },
    { src: ' ', alt: ' ' },
    { src: ' ', alt: ' ' },
  ],
}

const topRightImage = {
  path: '/images/home/lottery-balls/',
  attributes: [
    { src: ' ', alt: ' ' },
    { src: ' ', alt: ' ' },
    { src: ' ', alt: ' ' },
    { src: ' ', alt: ' ' },
    { src: ' ', alt: ' ' },
  ],
}

const WinSection = () => {
  // eslint-disable-next-line
  const { theme } = useTheme()

  return (
    <>
      <BgWrapper>
        <BottomLeftImgWrapper>
          <CompositeImage {...bottomLeftImage} />
        </BottomLeftImgWrapper>
        <TopRightImgWrapper>
          <CompositeImage {...topRightImage} />
        </TopRightImgWrapper>
      </BgWrapper>
     
    </>
  )
}

export default WinSection
